// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-meal-plans-js": () => import("./../../../src/pages/category/meal-plans.js" /* webpackChunkName: "component---src-pages-category-meal-plans-js" */),
  "component---src-pages-category-programs-js": () => import("./../../../src/pages/category/programs.js" /* webpackChunkName: "component---src-pages-category-programs-js" */),
  "component---src-pages-category-supplements-js": () => import("./../../../src/pages/category/supplements.js" /* webpackChunkName: "component---src-pages-category-supplements-js" */),
  "component---src-pages-category-workout-plans-js": () => import("./../../../src/pages/category/workout-plans.js" /* webpackChunkName: "component---src-pages-category-workout-plans-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

