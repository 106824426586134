const productData = {
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzc1MTcxMjQxNTkzNg==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMjE0NjA1NjQxNjA=",
        productId: 6121460564160,
        productVariantId: 37751712415936,
        type: "supplements",
        title: "I ❤ Vanilla Protein",
        notice: '',
        img: 'vanilla',
        quote: "This is literally the BEST vanilla protein powder I have ever tasted, and I'm super picky!",
        quoteAuthor: 'Heather M',
        collection: 'proteins',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 555090112,
                orderIntervalFrequency: 30,
                sellingPlanName: "Deliver every 30 Days"
            },
            {
                sellingPlanId: 555122880,
                orderIntervalFrequency: 60,
                sellingPlanName: "Deliver every 60 Days"
            },
            {
                sellingPlanId: 555155648,
                orderIntervalFrequency: 90,
                sellingPlanName: "Deliver every 90 Days"
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDgwODcwNzg2NjY5NA==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwODgyOTQ1MTQ3NTg=",
        productId: 2088294514758,
        productVariantId: 20808707866694,
        type: "supplements",
        title: "Berry Green Protein",
        notice: 'new',
        img: 'bgp',
        quote: 'I love that the Berry Green Protein is delicious just with water when I’m in a hurry or I can put it in my smoothies - and I’m going to bake with it next!',
        quoteAuthor: 'Holly L',
        collection: 'proteins',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 555286720,
                orderIntervalFrequency: 30,
                sellingPlanName: "Deliver every 30 Days"
            },
            {
                sellingPlanId: 555319488,
                orderIntervalFrequency: 60,
                sellingPlanName: "Deliver every 60 Days"
            },
            {
                sellingPlanId: 555352256,
                orderIntervalFrequency: 90,
                sellingPlanName: "Deliver every 90 Days"
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDE0NzU1NzY0NjUyOA==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY3NTIwMzM3MzQ4NDg=",
        productId: 6752033734848,
        productVariantId: 40147557646528,
        type: "supplements",
        title: "Rock and Restore",
        notice: 'sale',
        img: 'rock-and-restore',
        quote: 'I notice a difference in my recovery time for my workouts, and my muscle definition seems to be popping more.',
        quoteAuthor: 'Amanda M.',
        collection: 'proteins',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 554991808,
                orderIntervalFrequency: 30,
                sellingPlanName: "Deliver every 30 Days"
            },
            {
                sellingPlanId: 555024576,
                orderIntervalFrequency: 60,
                sellingPlanName: "Deliver every 60 Days"
            },
            {
                sellingPlanId: 555057344,
                orderIntervalFrequency: 90,
                sellingPlanName: "Deliver every 90 Days"
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTI3NTI4MjM5OTMwMg==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzNjg2NjQ4NTQ1OTg=",
        productId: 4368664854598,
        productVariantId: 31275282399302,
        type: "supplements",
        title: "Full Body Collagen",
        notice: 'sale',
        img: 'fbc',
        quote: 'I have used other collagens in the past and this one is by far the cleanest and the smoothest - it’s completely tasteless and it’s very versatile!',
        quoteAuthor: 'Stephenie M',
        collection: 'proteins',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 555188416,
                orderIntervalFrequency: 30,
                sellingPlanName: "Deliver every 30 Days"
            },
            {
                sellingPlanId: 555221184,
                orderIntervalFrequency: 60,
                sellingPlanName: "Deliver every 60 Days"
            },
            {
                sellingPlanId: 555253952,
                orderIntervalFrequency: 90,
                sellingPlanName: "Deliver every 90 Days"
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjA4NDM0OTE1NzQ0Ng==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ2Nzk2MjAwMzQ2MzA=",
        productId: 4679620034630,
        productVariantId: 32084349157446,
        type: "supplements",
        title: "SereniCalm",
        notice: 'new',
        img: 'serenicalm',
        quote: "I feel so much lighter, like a burden has been lifted from me. Serenicalm works extremely well for me, and I would highly recommend this product to anyone who suffers any form of anxiety or stress.",
        quoteAuthor: 'Patricia B',
        collection: 'other',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 554893504,
                orderIntervalFrequency: 30,
                sellingPlanName: "Deliver every 30 Days"
            },
            {
                sellingPlanId: 554926272,
                orderIntervalFrequency: 60,
                sellingPlanName: "Deliver every 60 Days"
            },
            {
                sellingPlanId: 554959040,
                orderIntervalFrequency: 90,
                sellingPlanName: "Deliver every 90 Days"
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTQzMTcwMDU0NTYwNg==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ0MjM4OTU1ODA3NDI=",
        productId: 4423895580742,
        productVariantId: 31431700545606,
        type: "supplements",
        title: "Whole Sleep",
        notice: 'sale',
        img: 'wholesleep',
        quote: 'Taking this product I was able to get to sleep no problem, I stayed asleep and I didn\'t wake up throughout the night like I usually do.',
        quoteAuthor: 'Sherri J',
        collection: 'other',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 554795200,
                orderIntervalFrequency: 30,
                sellingPlanName: "Deliver every 30 Days"
            },
            {
                sellingPlanId: 554827968,
                orderIntervalFrequency: 60,
                sellingPlanName: "Deliver every 60 Days"
            },
            {
                sellingPlanId: 554860736,
                orderIntervalFrequency: 90,
                sellingPlanName: "Deliver every 90 Days"
            }
        ]

    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDczNjE0NjYyNA==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0NTE0NjU5MjA=",
        productId: 6667451465920,
        productVariantId: 39790736146624,
        type: "meal plan",
        notice: 'sale',
        title: 'The Body Fuel System',
        img: 'bfs',
        quote: 'The Body Fuel System was everything I needed to get over my weight plateau!',
        quoteAuthor: 'Jenna T.',
        collection: 'meal plans',
        video: "249962643",
        paymentOptions: 1
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDY5NTY3ODE0NA==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0Mjg0OTU1NTI=",
        productId: 6667428495552,
        productVariantId: 39790695678144,
        type: "meal plan",
        notice: '',
        title: '30-Day Challenge Meal Plan',
        img: '30dmp',
        quote: 'I got the 30 day meal plan and have been loving it! I am now down a total of 14 pounds as of this morning!',
        quoteAuthor: 'Richelle J.',
        collection: 'meal plans',
        video: "142589434",
        paymentOptions: 1
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDEyOTkwNTg1Ng==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2NjcxOTI0Njc2NDg=",
        productId: 6667192467648,
        productVariantId: 39790129905856,
        type: "meal plan",
        notice: 'sale',
        title: '7-Day Meal Plan',
        img: '7dmp',
        quote: 'Great coaching, great recipes, and down to earth mentoring. This rocks!',
        quoteAuthor: 'Riona H.',
        collection: 'meal plans',
        paymentOptions: 1
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc2NjkxNTc3Ng==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0NjgzNDE0NDA=",
        productId: 6667468341440,
        productVariantId: 39790766915776,
        type: "workout plan",
        notice: '',
        title: '90-Day Home Workout Challenge',
        img: '90dch',
        quote: 'I’ve said it before and I’ll say it again – doing your programs has given me so much confidence. I think this is the best I have felt EVER!',
        quoteAuthor: 'Angela H.',
        collection: 'workout program',
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 544964800,
                sellingPlanName: "You will be charged $49 today, and an additional $49 next 2 months",
                price: 147,
                months: 3
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDc4Njc0MDQxNg==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0NzkyNTMxODQ=",
        productId: 6667479253184,
        productVariantId: 39790786740416,
        type: "workout plan",
        notice: 'sale',
        title: 'Home Workout Domination',
        img: 'hwd',
        quote: 'I’ve been working out and eating well for a long time, but nothing had changed – I think I’ve hit the winning combination with Home Workout Domination!',
        quoteAuthor: 'Jennifer H.',
        subscribed: true,
        collection: 'workout program',
        video: "229374313",
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 555450560,
                sellingPlanName: "You will be charged $39 today, and an additional $39 next month.",
                price: 78,
                months: 2
            }
        ]
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgyMzc2ODI1Ng==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0ODY4NTUzNjA=",
        productId: 6667486855360,
        productVariantId: 39790823768256,
        type: "workout plan",
        notice: 'sale',
        title: '30-Day Booty + Abs Challenge',
        img: 'bootyabs',
        quote: 'I’m REALLY pleased with the changes my body is making! I feel stronger, sexier, lighter, and generally better inside and out.',
        quoteAuthor: 'Laura M.',
        collection: 'workout program',
        video: "142302588",
        paymentOptions: 1
    },
    "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTc5MDgxMDMwMDYwOA==" : {
        shopifyId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2Njc0ODI3NTkzNjA=",
        productId: 6667482759360,
        productVariantId: 39790810300608,
        type: "workout plan",
        notice: '',
        title: 'Lioness',
        img: 'lioness',
        quote: 'After years of trying to get my body back, the Lioness Program gave me the structure, motivation, and ease of use I’ve been searching for.',
        quoteAuthor: 'Julie M.',
        collection: 'workout program',
        video: "257596208",
        paymentOptions: 2,
        sellingPlans: [
            {
                sellingPlanId: 555483328,
                sellingPlanName: "You will be charged $39 today, and an additional $39 next 2 months.",
                price: 117,
                months: 3
            }
        ]
    }
}

export default productData;
