import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"
import * as queryString from "query-string";

const isBrowser = typeof window !== "undefined"

export const onClientEntry = () => {
    // Handle the route parameter destructuring
    const { query } = isBrowser && queryString.parseUrl(window.location.href);

    // If the query has a "loc" object, use it
    if (query && query.loc) {
        if (query.loc === "US") {
            window.localStorage.setItem("country", "US");
        } else {
            window.localStorage.setItem("country", "worldwide");
        }
    }

    // Read the saved country (if it exists) from local storage
    const country = window.localStorage.getItem("country") || "";

    // If there's no user location saved in the local storage, fetch it from the API
    if (!country) {
        fetch("https://api.ipstack.com/check?access_key=a633d969f5f75cdd67c3b53fc4225ebf&fields=country_code", {
            method: "GET"
        }).then(res => res.json())
          .then(data => {
            if (data.country_code === "US") {
                window.localStorage.setItem("country", "US");
            } else {
                window.localStorage.setItem("country", "worldwide");
            }

            // Reload the page after fetching the user's coutnry code (only the first time when this data is fetched)
            // so components can re-read values from the local storage
              if(data.country_code !== "US") {
                  isBrowser && window.location.reload();
              }
          })
    }
}

export const wrapRootElement = ({ element }) => (
    <ThemeProvider>{element}</ThemeProvider>
)
