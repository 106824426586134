import React, {useEffect, useState} from "react"
import productData from "../../static/data/product";

const isBrowser = typeof window !== "undefined"

const defaultState = {
    addVariantToCart: (variantId, quantity, price, sellingPlan, title) => {},
    updateQuantityInCart: (variantId, quantity, selling_plan, isSidebar) => {},
    removeLineItemInCart: (lineItemId, paymentPlan) => {},
    sidebarOpen: false,
    handleSidebar: () => {},
    buttonLoader: false,
    checkoutUrl: '',
    total: 0,
    cart: []
}
const ThemeContext = React.createContext(defaultState)

const ThemeProvider = ({children}) => {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [buttonLoader, setButtonLoader] = useState(false)
    const [cart, setCart] = useState([])
    const [checkoutUrl, setCheckoutUrl] = useState('')
    const [total, setTotal] = useState(0);

    const handleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
    }
    useEffect(() => {
        if(sidebarOpen) {
            isBrowser && document.body.classList.add('overflow--hidden')
        } else {
            isBrowser && document.body.classList.remove('overflow--hidden')
        }
    },[sidebarOpen])

    // get the cartItems from localStorage on load, if they exist
    useEffect(()=>{

        const url = typeof window !== 'undefined' ? window.location.href : '';
        let parameters = {}, hash;
        let q = url.split('?')[1];
        if (q !== undefined) {
            q = q.split('&');
            for (let i = 0; i < q.length; i++) {
                hash = q[i].split('=');
                parameters[hash[0]] = hash[1];
            }
        }
        console.log(parameters)

        for (let key in parameters) {
            if (parameters.hasOwnProperty('cart')) {
                localStorage.removeItem('cartItems')
                setCart([])
            }
        }

        setCart((localStorage.getItem('cartItems') && localStorage.getItem('cartItems') !== null ) ? JSON.parse(localStorage.getItem('cartItems')) : []);
    },[])

    // add product to cart or if it exists, update it
    const addVariantToCart = (variantId, quantity, price, sellingPlan, title) => {

        setButtonLoader(true);

        if(variantId.indexOf("Shopify__ProductVariant__") !== -1) {
            variantId = variantId.split("Shopify__ProductVariant__")[1]
        }

        const isInCart = cart.filter(item => item.id === variantId && item.selling_plan === sellingPlan)

        if(isInCart.length) {
            updateQuantityInCart(variantId, quantity, sellingPlan, false)
        } else {
            quantity = Number(quantity)
            price = Number(price)
            sellingPlan = Number(sellingPlan)

            setCart([...cart, {
                id: variantId,
                quantity: quantity,
                price: price,
                selling_plan: sellingPlan ? sellingPlan : "No",
                title: title
            }])

            setSidebarOpen(true)
            setButtonLoader(false);
        }
    }

    // update the local storage on cart changes
    useEffect(()=>{
        localStorage.setItem('cartItems', JSON.stringify(cart));
    },[cart])

    // update existing product in cart
    const updateQuantityInCart = (variantId, quantity, selling_plan, isSidebar) => {
        let newCartItem = [];
        let newCart = Array.from(cart)

        if(newCart) {
            newCart.map((item)=>{
                if(item.id === variantId) {
                    if(item.selling_plan === selling_plan) {

                        if(isSidebar) {
                            item.quantity = quantity
                        } else {
                            item.quantity += quantity
                        }

                        setSidebarOpen(true)
                        if(quantity === 0) {
                            removeLineItemInCart(variantId,selling_plan)
                        } else {
                            setCart(newCart)
                            setButtonLoader(false);
                        }
                    } else {
                        newCartItem = {
                            id: item.id,
                            quantity: item.quantity,
                            price: item.price,
                            selling_plan: selling_plan
                        }
                    }
                }
            })

            if(newCartItem.length) {
                setCart([...cart, newCartItem])
                setButtonLoader(false);
            }
        }
    }

    // remove product from cart
    const removeLineItemInCart = (variantId, selling_plan) => {

        let newCart = Array.from(cart);
        newCart.map((item, index)=>{
            if(item.id === variantId && item.selling_plan === selling_plan) {
                newCart.splice(index, 1)
                setCart(newCart);
            }
        })
        localStorage.setItem('cartItems', JSON.stringify(newCart));
    }

    useEffect(() => {

        const storeUrl = 'https://checkout.thebettyrocker.com/cart/';
        let apiUrl = `${storeUrl}?`;
        let id;

        if(cart) {
            cart.forEach((item, index) => {
                id = productData[item.id].productVariantId
                apiUrl += `&id${index}=${id}&quantity${index}=${item.quantity}&selling_plan${index}=${item.selling_plan}`;
            });
            apiUrl += `&length=${cart.length}`;

            let counter = 0;
            cart.map((item) => {
                if(item) {
                    counter += (item.quantity * Number(item.price))
                }
            })
            setTotal(counter);
        }

        setCheckoutUrl(apiUrl)

    },[cart])


    return (
        <ThemeContext.Provider
            value={{
                addVariantToCart: (variantId, quantity, price, sellingPlan, title) => addVariantToCart(variantId, quantity, price, sellingPlan, title),
                updateQuantityInCart: (variantId, quantity, selling_plan, isSidebar) => updateQuantityInCart(variantId, quantity, selling_plan, isSidebar),
                removeLineItemInCart: (lineItemId, paymentPlan) => removeLineItemInCart(lineItemId, paymentPlan),
                sidebarOpen: sidebarOpen,
                buttonLoader: buttonLoader,
                handleSidebar: handleSidebar,
                checkoutUrl: checkoutUrl,
                total: total,
                cart: cart
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}
export default ThemeContext

export { ThemeProvider }
